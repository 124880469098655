@import 'variables.scss';

@mixin mainFont {
  color: $white-color;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

@mixin tablet {
  @media (min-width: $tablet-width) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-width) {
    @content;
  }
}

@mixin retina {
  @media (min-resolution: $retina-resolution) {
    @content;
  }
}