@import '../../variables.scss';
@import '../../mixins.scss';

.section {
  margin: 0 auto;
  margin-top: 12vh;
  width: 100%;
  max-width: 500px;
  height: auto;
  gap: 20px;
}

.title {
  max-width: 500px;
  width: calc(100% - 10px);
  margin-bottom: 25px;
  margin-left: 5px;
  min-height: 102px;
  background-color: $dark-blue-color;
  color: $white-color;
  font-family: "Gilroy", sans-serif;
  font-size: 75px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;

  & h1 {
    margin: 0;
  }
}

.form {
  padding: 79px 35px 30px 35px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: $white-color;
  color: $dark-blue-color;
  border-radius: 15px;
}

.signin {

  margin-top: 27px;
  margin-bottom: 50px;

  &Input {
    height: 65px;
    width: 100%;
    font: inherit;
    margin-bottom: 20px;
  }
}

.verifyButton {
  width: 250px;
  height: 50px;
  cursor: pointer;
}

.restoreButton {
  display: block;
  margin-top: 20px;
  margin-left: 44px;
  text-align: left;
  @include mainFont;
  text-decoration: none;
  color: $dark-blue-color;
}