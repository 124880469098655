@import "../../../variables.scss";
@import "../../../mixins.scss";

.header {
  padding-top: 16px;
  background-color: $dark-blue-color;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 1px, transparent 1px), linear-gradient(rgba(255, 255, 255, 0.1) 1px, transparent 1px);
  background-size: 20px 20px;
}

.headerInfo {
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.brand {
  &Info {
    font-family: "Code pro bold", sans-serif;
    display: block;
    border-bottom: 1px solid $white-color;
    padding-bottom: 10px;
    margin: 0;
    color: $white-color;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &Name {
    margin: 0;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: $white-color;
  }
}

.infoDate {
  display: block;
  min-width: 133px;
  max-width: 140px;
  border-bottom: 1px solid $white-color;
  margin: 0 auto 20px 0;
  padding-bottom: 3px;
  color: $white-color;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.study {

  &Info {
    @include mainFont;
    display: flex;
    flex-direction: row-reverse;
    gap: 55px;
    align-items: center;
    margin-bottom: 16px;

    & p {
      margin: 0;
    }
  }

  &Account {
    @include mainFont;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &Acc:after {
      display: block;
      width: 31px;
      height: 31px;
      content: url("./UserOutlined.svg");
  }

  &LogOut:after {
      display: block;
      width: 31px;
      height: 31px;
      content: url("./LogoutOutlined.svg");
  }
}

.navigation {
  background-color: $white-color;
  height: 64px;
  display: flex;
  flex-direction: row-reverse;
  border-bottom: 1px solid rgba(73, 70, 70, 0.2);
}

.nav {
  &Button {
    margin: 0 16px;
    display: flex;
    position: relative;
    flex-direction: row-reverse;
    align-items: center;
    fill: $black-color;
    color: $black-color;
    transition: 0.3s ease-in-out;
  }

  &Title {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    margin: 0 5px 0 0;
  }

  &Active {
    fill: $blue-color;
    color: $blue-color;
    border-bottom: 1px solid $blue-color;
    transition: 0.3s ease-in-out;
  }
}

.homeworkToggle {
  position: absolute;
  height: 100px;
  width: 200px;
  top: 70px;
  left: 0;
  background-color: $white-color;
  color: $black-color;
  font-size: 14px;
  box-shadow: 1px 0 10px black;
  z-index: 5;
  border-radius: 5px;

  &Type {
    display: block;
    text-decoration: none;
    margin: 5px;
    height: 30px;
    padding-top: 10px;
    border-radius: 5px;

    @include mainFont;

    font-size: 15px;
    color: $dark-blue-color;

    &:hover {
      background-color: rgb(183, 179, 179);
      color: $white-color;
    }
  }
}


@media (max-width: 600px) {
  .headerInfo {
    flex-direction: column;
  }

  .infoDate {
    display: none;
  }

  .study {
    &Info {
      flex-direction: column;
      margin-top: 0;
    }
  }

  .brandInfo {
    margin-bottom: 20px;
  }
}
