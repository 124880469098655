$blue-color: #1282A7;
$dark-blue-color: #092F56;
$white-color: #ffffff;
$black-color: rgba(0, 0, 0, 0.88);

// Viewports

$tablet-width: 768px;
$desktop-width: 1600px;
$mobile-width-only: 767px;

//retina

$retina-resolution: 2dppx;