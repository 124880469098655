@import "variables.scss";
@import "mixins.scss";

.App {
  text-align: center;
}

select:not([style]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
}

option:not([style]) {
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
}

.MuiDataGrid-footerContainer {
  display: none !important;
}

a {
  text-decoration: none;
  color: inherit;
}

.input-ltr {
  direction: ltr !important;
}