@import 'variables.scss';
@import "mixins.scss";

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Code pro bold";
  src: url("../fonts/code-pro-bold-lc.otf");
}

body {
  margin: 0;
  font-family: 'Gilroy' , 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mainFont;

  width: 100%;

  background-color: $blue-color;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 1px, transparent 1px), linear-gradient(rgba(255, 255, 255, 0.1) 1px, transparent 1px);
  background-size: 20px 20px;
}

a {
  text-decoration: none;
}

.react-time-picker__wrapper {
  color: black;
}

::-webkit-scrollbar {
  width: 0;
}

